<template>
  <skeleton
    v-if="isLoading"
    borderRadius="10px"
    height="20rem"
    class="skeleton-dark p-3"
  />
  <div v-else class="bg-primary-5 p-4 border-r-10">
    <h2 class="text-primary-0 fs-4 fw-bold mb-3">Last class info</h2>
    <div class="field-grid align-items-center">
      <p class="fw-bold fs-6 lh-1">Last teacher</p>
      <p class="text-primary-1 fs-6 lh-sm">
        {{ teacher ?? "-" }}
      </p>
    </div>
    <div class="field-grid align-items-center">
      <p class="fw-bold fs-6 lh-1">Bright path’s progress</p>
      <p class="text-primary-1 fs-6 lh-sm">
        {{ bright_paths_progress ?? "-----" }}
      </p>
    </div>
    <div class="field-grid field-blue">
      <p class="fw-bold fs-6 lh-sm">Class summary</p>
      <Textarea
        :autoResize="true"
        class="w-100 fs-6 border-r-10"
        placeholder="Resume"
        :model-value="class_resume"
        :value="class_resume"
        :disabled="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "UserProgressComponent",
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    teacher: {
      type: String,
      default: "",
    },
    class_resume: {
      type: String,
      default: "",
    },
    bright_paths_progress: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.field-grid {
  display: grid;

  grid-template-columns: 7rem 1fr;
  column-gap: 10px;
  margin-bottom: 0.6rem;
}
</style>