<template>
  <skeleton
    v-if="isLoading"
    borderRadius="10px"
    height="20rem"
    class="skeleton-dark p-3"
  />
  <div v-else class="bg-white p-4 border-r-10">
    <div class="mb-2 d-flex gap-2">
      <p class="fs-1-1 fw-bold d-inline w-50">Learning goal:</p>
      <p class="fs-1-1 d-inline">
        {{ learningGoal ?? "----" }}
      </p>
    </div>
    <p class="fs-1-1 fw-bold">About me:</p>
    <div class="field-blue w-100">
      <Textarea
        :autoResize="true"
        class="w-100 fs-6 border-r-10 mt-2"
        placeholder="About me"
        :disabled="true"
        :value="about_me"
        :model-value="about_me"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "UserAboutComponent",
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    learning_goal: {
      type: String,
      default: "",
    },
    about_me: {
      type: String,
      default: "",
    },
  },
  computed:{
    learningGoal(){
      return this.learning_goal.split(':')[1]
    }
  }
};
</script>