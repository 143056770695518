<template>
  <skeleton
    v-if="isLoading"
    borderRadius="10px"
    height="20rem"
    class="skeleton-dark p-3"
  />
  <div class="bg-primary-5 p-4 border-r-10" v-else>
    <h2 class="text-primary-0 fs-4 fw-bold mb-3">Class progress</h2>
    <div class="d-flex justify-content-between gap-1">
      <p class="fw-bold fs-1-1 lh-1">Total classes/hours</p>
      <p class="fw-bold fs-1-1 lh-1 text-primary-1">
        {{ totalClasses }}/{{ totalClasses / 2 }}hrs
      </p>
    </div>
    <hr />
    <p class="fw-bold fs-1-1 lh-1 text-primary-0 mb-3">
      Classes taken this week
    </p>
    <user-progress-week-component
      :isLoading="isLoading"
      :classWeek="classProgress"
    />
  </div>
</template>

<script>
import UserProgressWeekComponent from "@/shared/components/UserProfile/UserProgressWeekComponent";
export default {
  name: "UserClassProgressComponent",
  components: {
    UserProgressWeekComponent,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    totalClasses: {
      type: Number,
      default: 0,
    },
    classProgress: {
      type: Array,
      default: [],
    },
  },
  setup() {},
};
</script>

