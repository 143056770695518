<template>
  <skeleton
    v-if="isLoading"
    borderRadius="10px"
    height="20rem"
    class="skeleton-dark"
  />
  <div
    class="progress-list pe-2 scroll pb-3 scroll-secondary"
    v-if="classWeek.length > 0 && !isLoading"
  >
    <div
      v-for="(classe, index) in classWeek"
      :key="index"
      class="progress-bar-grid progress-square mb-2 mt-2"
    >
      <p class="fw-bold">{{ classe.day }}</p>
      <progress-bar
        :value="(classe.count * 100) / 20"
        :showValue="false"
        class="w-100"
        :class="index === day - 1 ? 'progress-c1' : 'progress-a1'"
      />
      <p class="text-primary-1 text-end">{{ classe.count }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserProgressWeekComponent",
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    classWeek: {
      type: Array,
      default: [],
    },
  },
  setup() {
    return {};
  },
};
</script>

