<template>
  <user-information
    :isLoading="isLoadingProfile"
    :name="studentProfile.info?.name"
    :lastname="studentProfile.info?.last_name"
    :age="studentProfile.info?.age"
    :gender="studentProfile.info?.gender"
    :timeZone="studentProfile.info?.timezone_change"
    :photo="studentProfile.info?.photo"
    :toggleUpgradeLevel="() => toggleUpgradeLevelModal(true)"
    :levels="levelsInfo"
    :showCardRole="true"
    :status="studentProfile.info?.status"
    :currentLevel="studentProfile.info.level"
    :goPreviousPage="goPreviousPage"
    :memberSince="studentProfile.info?.member_since"
  />

  <div class="mt-4 grid-">
    <user-about-component
      :isLoading="isLoadingProfile"
      :learning_goal="studentProfile.info?.learning_goal"
      :about_me="studentProfile.info?.about_me"
    />
    <user-progress-component
      :isLoading="isLoadingProfile"
      :teacher="studentProfile?.lastClass?.teacher"
      :bright_paths_progress="studentProfile.lastClass?.bright_paths_progress"
      :class_resume="studentProfile.lastClass?.class_resume"
    />
    <user-class-progress-component
      :isLoading="isLoadingProfile"
      :totalClasses="studentProfile.totalClasses"
      :classProgress="studentProfile.classProgress"
    />
  </div>

  <div class="mt-4">
    <user-table-feedback-component
      :isLoading="isLoadingProfile"
      :comments="studentProfile.historyLessons"
      :headersComments="headersComments"
    />
  </div>
</template>

<script>
import useStudent from "@/modules/admin/composables/Members/Students/useStudentProfile";
import useStudents from "@/modules/admin/composables/Members/Students/useStudents";
import useInformation from "@/modules/admin/composables/Members/Students/useInformation";
import { headers as headersComments } from "@/modules/teachers/data/Students/commentsTableData";

import UserInformation from "@/shared/components/UserProfile/UserInformation";
import UserAboutComponent from "@/shared/components/UserProfile/UserAboutComponent";
import UserProgressComponent from "@/shared/components/UserProfile/UserProgressComponent";
import UserClassProgressComponent from "@/shared/components/UserProfile/UserClassProgressComponent";
import UserTableFeedbackComponent from "@/shared/components/UserProfile/UserTableFeedbackComponent";

export default {
  components: {
    UserInformation,
    UserAboutComponent,
    UserProgressComponent,
    UserClassProgressComponent,
    UserTableFeedbackComponent,
  },
  setup() {
    const { isLoadingProfile, studentProfile, levelsInfo  } = useStudent();
    const { goToPage } = useStudents();
    const { toggleUpgradeLevelModal } = useInformation();
    const goPreviousPage = () => {
      goToPage({
        name: "admin-members-students",
      });
    };
    return {
      isLoadingProfile,
      studentProfile,
      levelsInfo,
      goPreviousPage,
      toggleUpgradeLevelModal,
      headersComments,
    };
  },
};
</script>

<style lang="scss" scoped>
.grid- {
  display: grid;
  grid-template-columns: 24rem repeat(2, 2fr);
  gap: 1rem;
}
</style>
